import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Button as MuiButton,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { ReactComponent as Logo } from "vendor/logo.svg";
import { ReactComponent as OktoLogo } from "vendor/oktaLogo.svg";
import { useAuthContext } from "auth/use-auth-context";
import Auth from "layouts/Auth";
import {
  selectAtlasInstance,
  selectLoggedInUser,
  selectLoginFailMessage,
  setAtlasInstance,
} from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useAtlasInstances from "hooks/useAtlasInstances";
import { capitalize } from "lodash";

const AppTitle = styled(Typography)`
  margin: 0;
`;

const SignInButton = styled(MuiButton)`
  grid-column-start: 1;
  grid-column-end: 4;
`;

const OktaLogo = styled(OktoLogo)`
  margin: 0 auto;
  height: 15px;
  margin-bottom: 2px;
  grid-column-start: 1;
  grid-column-end: 3;
  fill: ${(props) => props.theme.palette.primary.contrastText};
`;

const ErrorWarningText = styled(Typography)`
  margin: 0 auto;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 240px;
  text-align: center;
`;

const ATLASIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  fill: ${(props) => props.theme.palette.primary.main};
  margin: 0 auto;
  width: 60px;
  height: 40px;
  display: block;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

function InstanceSelect() {
  const [showErrorText, changeShowErrorText] = useState(false);
  const loginFailMessage = useSelector(selectLoginFailMessage);
  const loggedInUser = useSelector(selectLoggedInUser);
  const atlasInstance = useSelector(selectAtlasInstance);
  const dispatch = useDispatch();
  const { logout } = useAuthContext();
  const { userInstances, isLoadingUserInstances, userInstancesError } =
    useAtlasInstances();

  return (
    <Auth>
      <Container>
        {userInstances.length > 1 && (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              my: 3,
            }}
          >
            <AppTitle variant="h1" align="center" gutterBottom>
              Select Instance
            </AppTitle>
          </Box>
        )}
        {isLoadingUserInstances && userInstances.length <= 1 ? (
          <CircularProgress sx={{ margin: "0 auto" }} />
        ) : userInstances.length > 1 ? (
          <Select
            value={atlasInstance || ""}
            onChange={(e) => dispatch(setAtlasInstance(e.target.value))}
            labelId="instance-select"
          >
            {userInstances.map((instance) => (
              <MenuItem value={`${instance.name}`} key={instance.name}>
                {capitalize(instance.name)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography component="h1" variant="h1" align="center" gutterBottom>
              Error
              <Typography component="h2" variant="body1" align="center">
                {(userInstancesError as any)?.message ||
                  loginFailMessage?.title}
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: 3, width: 200 }}
              onClick={logout}
            >
              Logout
            </Button>
          </Box>
        )}
      </Container>
    </Auth>
  );
}

export default InstanceSelect;
