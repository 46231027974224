import pluralize from "pluralize";
import Themes from "types/Themes";
import { addDays, format, formatISO, isValid, parse } from "date-fns";

export const formatPhone = (phone: string) => {
  const newPhone = `+${phone.slice(0, phone.length - 10)} (${phone.slice(
    phone.length - 10,
    phone.length - 7
  )}) ${phone.slice(phone.length - 7, phone.length - 4)}-${phone.slice(
    phone.length - 4,
    phone.length
  )}`;
  return newPhone;
};

export const isLightTheme = (theme: Themes) => {
  return Themes.light === theme;
}

export function alphabetizeByProperty<T>(propertyName: keyof T) {
  return (a: T, b: T) => {
    return a[propertyName] > b[propertyName]
      ? 1
      : a[propertyName] < b[propertyName]
      ? -1
      : 0;
  };
}

export function highestToLowest(
  a: number | string,
  b: number | string,
){
  return a > b
    ? -1
    : a < b
    ? 1
    : 0;
}

export function hoursToMinutes(time: number){
  return Number((time * 60).toFixed(2));
}

export function minutesToHours(time: number){
  return Number((time / 60).toFixed(2));
}

export const formatTimeRequired = (minutes: number) => {
  const hours = Number((minutes / 60).toFixed(2));

  if (hours < 1) return `${minutes.toFixed(0)} ${pluralize("minute", minutes)}`;
  else {
    return `${hours} ${pluralize("hour", hours)}`;
  }
};

export function parseProjectDate(projectDate: string){
  return parse(projectDate, "yyyy-MM-dd", new Date());
}

export function correctProjectModalDate(projectDate: string){
  return formatISO(addDays(new Date(projectDate), 2), { representation: "date"}) as DateString;
}

export type DateString = `${number}${number}${number}${number}-${number}${number}-${number}${number}` | "";

export function replaceSingleDoubleQuoteWithDoubleDoubleQuote(string: string) {
  return string.replace(/"/g, '""')
}

export function toPrettyDateTime(date: Date | string) {
  return format(new Date(date), "dd MMM-yyyy hh:mma")
} 

export function parsePossibleFloat(number: number) {
  return number % 1 !== 0 ? number.toFixed(2) : number
}

export function isFalsyString (string: string){
  return string === "undefined" || string === "null" || string === "";
}

export function minutesToFractionalHours(minutes: number) {
  return Math.round(( minutes / 60 ) * 100)/100;
}
