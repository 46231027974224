import Themes from "types/Themes";

export type IUser = {
  _id: string;
  lastName: string;
  firstName: string;
  email: string;
  lastLogin: string;
  userRole: string;
  phoneNumber: string;
  defaultTheme: Themes;
  active: boolean;
};

export const initialState: IUser = {
  _id: "",
  lastName: "",
  firstName: "",
  email: "",
  lastLogin: "",
  phoneNumber: "",
  userRole: "VIEWER",
  defaultTheme: Themes.light,
  active: true,
};
