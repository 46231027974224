import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry } from "@reduxjs/toolkit/query/react";
import { protectedResources } from "auth/authConfig";
import { msalInstance } from "auth/context/azure/msalInstance";
import { APP_VERSION } from "store/api/APP_VERSION";
import { setAtlasInstance } from "store/slices/authSlice";
import { AppStateType } from "store/store";

function isSendFileEndpoint(endpoint: string) {
  const fileEndpoints = ["projects/sync"];
  return fileEndpoints.some(e => endpoint.toLowerCase().includes(e.toLowerCase()));
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const user = (getState() as AppStateType).auth.loggedInUser;
    const atlasInstance = (getState() as AppStateType).auth?.atlasInstance
    headers.set('instance-id', atlasInstance || "")
    isSendFileEndpoint(endpoint) ? headers.delete("Content-Type") :  headers.set("Content-Type", "application/json");
    headers.set("app-version", String(APP_VERSION));
    user && headers.set("userid", user._id);
    const result = await msalInstance.acquireTokenSilent({
      scopes: protectedResources.atlasApi.scopes.read,
    });
    const bearer = `Bearer ${result.accessToken}`;
    headers.append('Authorization', bearer);

    return headers;
  },
  credentials: "include",
  
});

const baseQueryWithInstancePermissionProtect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && (result?.error?.data as any)?.message === "You do not have access to this instance") {
    api.dispatch(setAtlasInstance(null));
  }
  return result;
};

const baseQueryWithRetry = retry(baseQueryWithInstancePermissionProtect, { maxRetries: 6 });

export const atlasApi = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    "UserPreferences",
    "ShopMap",
    "ShopMapStation",
    "Plan",
    "PlanName",
    "Plans",
    "PlanAndTaktSheets",
    "PlansForProjects",
    "PopulatedPlan",
    "TaktSheet",
    "TaktSheetId",
    "Lookups",
    "TaktSheetIssues",
    "MaterialRecommendations",
    "StationIssues",
    "ActiveIssues",
    'Zips',
    "Projects",
    "Materials",
    "Employees",
    "Qualifications",
    "User",
    "Stations",
    "Configs",
    "Sites",
    "Areas",
    "Labels",
    "Tasks",
    "BKMS",
    "Instances",
    "InstancePermissions",
    "DataGridFilterFavorites"
  ],
  endpoints: () => ({}),
  refetchOnReconnect: true,
});



