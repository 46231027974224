import { HTTPMethod } from "store/api/utils/HttpMethodsEnum";
import ApiEvents from "types/ApiEvents";
import { setGlobalAlert } from "store/slices/systemSlice";
import { GlobalAlert } from "types/GlobalAlert";
import HttpResponse, { HttpErrorResponse, HttpSuccessResponse } from "types/HttpResponse";
import { protectedResources } from "auth/authConfig";
import { msalInstance } from "auth/context/azure/msalInstance";
import atlasStore from "store/store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

let store: typeof atlasStore

export const injectStore = (_store: any) => {
  store = _store
}



export interface APIOptions<T> {
    route: string;
    method?: HTTPMethod;
    body?: any;
    file?: boolean;
    fileResponseFileName?: string;
    fileResponseIsError?: boolean;
    enableAutoAlert?: boolean;
    showSuccessMessage?: boolean;
    successMessageDuration?: number;
    updateStoreCallBack?: (data: T) => {payload: T, type: string};

}

export async function makeAPICallv2<T>(options: APIOptions<T> & ApiEvents<T>) {


  const tokenResult = await msalInstance.acquireTokenSilent({
    scopes: protectedResources.atlasApi.scopes.read,
  });

  const bearer = `Bearer ${tokenResult.accessToken}`;

  const {
    route,
    method = HTTPMethod.GET,
    body,
    file = false,
    fileResponseFileName = "errors",
    fileResponseIsError = true,
    enableAutoAlert = true,
    showSuccessMessage = false,
    successMessageDuration = 3000,
    updateStoreCallBack,
    onSuccess = () => {},
    onError = () => {},
    onComplete = () => {},
  } = options;



  function getRequestBody() {
    switch (true) {
      case body && file:
        return body;
      case body && !file:
        return JSON.stringify(body);
      default:
        return null;
    }
  }

  const config: RequestInit = {
    method,
    credentials: "include",
    body: getRequestBody(),
  };

  config.headers = new Headers();
  if (!file) {
    config.headers.set("content-type", "application/json");
  }
  config.headers.append("Authorization", bearer);
  config.headers.append("instance-id", store.getState().auth.atlasInstance!);
  const user = store.getState().auth.loggedInUser;
  user && config.headers.set("userid", user._id);
  
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${route}`,
      config
    );
    let fileResponse = false;
    for (var item of response.headers.entries()) {
      if (item[0] === "fileresponse" && item[1] === "true") fileResponse = true;
    }

    if (fileResponse) {
      handleFileResponse(response, fileResponseFileName);
      if (fileResponseIsError) {
        throw new Error(
          `Oops! Something went wrong. See ${fileResponseFileName} for details.`
        );
      }
    } else {
      const result: HttpResponse<T> = await response.json();

      if (response.ok) {
        onSuccess(result.body as T);
        showSuccessMessage &&
          sendAlert({
            message: result.message,
            severity: "success",
            show: true,
            duration: successMessageDuration,
          });
        updateStoreCallBack &&
          store.dispatch(updateStoreCallBack(result.body as T));
      } else {
        onError(result.message);
        enableAutoAlert &&
          sendAlert({
            message: result.message,
            severity: "warning",
            show: true,
          });
      }
    }
  } catch (error: any) {
    const message = errorMessageConversion(error.message);
    enableAutoAlert && sendAlert({ message, severity: "error" });
  }

  onComplete();
}

function errorMessageConversion(message: string) {
  if (message === "Failed to fetch") {
    return "We're having difficulty connecting to the interet, try checking your internet connection";
  } else return message;
}

function sendAlert(alert: GlobalAlert) {
  store.dispatch(setGlobalAlert({ ...alert, show: true }));
}


export const handleFileResponse = async (response: any, fileName: string) => {
  const result = await response.blob();

  const href = window.URL.createObjectURL(result);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export function invalidCookie(response: Response){
  return response.status === 401
}

export const getQueryParams = (
  query: Record<string | number, string | undefined>
): string => {
  return Object.entries(query).reduce(
    (q: string, [key, value], index) => {
      if (value !== undefined) {
        q.length ? q.concat(`&${key}=${value}`) : q = `${key}=${value}`
      } 
      return q;
    },
    ""
  );
};

export function getResponseBody<T>(response: HttpSuccessResponse<T>) {
  return response.body
}

export function getErrorResponseBody<T>(response: FetchBaseQueryError) {
  return response.data
}


