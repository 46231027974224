import React, { useEffect, useRef, useState } from "react";
import {styled} from "@mui/material"
import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Popover as MuiPopover,
  Select,
  SvgIcon,
  Switch,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import IssueIcon from "@mui/icons-material/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import RollCallIcon from "@mui/icons-material/AssignmentInd";
import {
  selectIssueCategoryFilter,
  selectIssueNumberFilter,
  setIssueCategoryFilter,
  setIssueNumberFilter
} from "store/slices/issueSlice";
import {
  selectShowConsumedHours,
  selectShowContractorStations,
  selectShowRollCalledStations,
  setShowConsumedHours,
  setShowContractorStations,
  setShowRollCalledStations,
  selectShowEndOfSerialNumber,
  selectShowPlannedHours,
  setShowPlannedHours,
  selectFilterTaktTime,
  setShowEndOfSerialNumber,
  setFilterTaktTime,
} from "store/slices/stationSlice";
import PersonPinCircle from "@mui/icons-material/PersonPinCircle";
import Timelapse from "@mui/icons-material/Timelapse";
import Search from "@mui/icons-material/Search";
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaktTimeIcon from '@mui/icons-material/WatchLater';
import Tag from "@mui/icons-material/Tag";
import { useGetLookupByNameQuery } from "store/services/lookups";
import { getUniqueIssueCategories, IssueCategoriesByIssueType, LOOKUPS } from "store/slices/lookupSlice";
import { selectAtlasInstance } from "store/slices/authSlice";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const IssueFilterHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

interface FilterProps {
  label: string;
  filterInput?: JSX.Element | null;
  secondaryAction?: JSX.Element | null;
  Icon: React.ElementType;
}

const Filter: React.FC<FilterProps> = ({
  label,
  filterInput = null,
  secondaryAction = null,
  Icon,
}) => {
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={label}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={filterInput}
      />
      <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
    </ListItem>
  );
};

function SiteFilterDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const issueCategoryFilter = useSelector(selectIssueCategoryFilter);
  const issueNumberFilter = useSelector(selectIssueNumberFilter)
  const showRollCalled = useSelector(selectShowRollCalledStations);
  const dispatch = useDispatch();
  const showConsumedHours = useSelector(selectShowConsumedHours);
  const showContractorStations = useSelector(selectShowContractorStations);
  const showPlannedHours = useSelector(selectShowPlannedHours);
  const showEndOfSerialNumber = useSelector(selectShowEndOfSerialNumber);
  const taktTime = useSelector(selectFilterTaktTime);
  const activeFilters = numberOfActiveFilters();
  const atlasInstance = useSelector(selectAtlasInstance);

  useEffect(() => {
    const preset = localStorage.getItem(`${atlasInstance}-siteFilter`);
    if (preset) {
      const parsedPreset: {
        issueCategoryFilter: string;
        showRollCalled: boolean;
        showConsumedHours: boolean;
        showContractorStations: boolean;
        showPlannedHours: boolean;
        showEndOfSerialNumber: boolean;
        taktTime: number;
        issueNumberFilter: number;
      } = JSON.parse(preset);
      dispatch(setIssueCategoryFilter(parsedPreset.issueCategoryFilter));
      dispatch(setShowRollCalledStations(parsedPreset.showRollCalled));
      dispatch(setShowConsumedHours(parsedPreset.showConsumedHours));
      dispatch(setShowContractorStations(parsedPreset.showContractorStations));
      dispatch(setShowPlannedHours(parsedPreset.showPlannedHours));
      dispatch(setShowEndOfSerialNumber(parsedPreset.showEndOfSerialNumber));
      dispatch(setFilterTaktTime(parsedPreset.taktTime));
      dispatch(setIssueNumberFilter(parsedPreset.issueNumberFilter));
    }
  }, []);

  useEffect(
    function onFilterChangeSetLocalStorage() {
      localStorage.setItem(
        `${atlasInstance}-siteFilter`,
        JSON.stringify({
          issueCategoryFilter,
          showRollCalled,
          showConsumedHours,
          showContractorStations,
          showPlannedHours,
          showEndOfSerialNumber,
          taktTime,
          issueNumberFilter,
        })
      );
    },
    [
      issueCategoryFilter,
      showRollCalled,
      showConsumedHours,
      showContractorStations,
      showPlannedHours,
      showEndOfSerialNumber,
      taktTime,
      issueNumberFilter,
    ]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function numberOfActiveFilters() {
    let count = 0;
    issueCategoryFilter && count++;
    issueNumberFilter && count++;
    showRollCalled && count++;
    showConsumedHours && count++;
    showContractorStations && count++;
    showPlannedHours && count++;
    showEndOfSerialNumber && count++;
    taktTime && count++;
    return count;
  }

  const {data: issueCategories = {}} = useGetLookupByNameQuery<{data: IssueCategoriesByIssueType}>(LOOKUPS.ISSUE_CATEGORIES_BY_ISSUE_TYPE);
  const uniqueIssueCategories = getUniqueIssueCategories(issueCategories);

  function clearAllFilters() {
    dispatch(setIssueCategoryFilter(""));
    dispatch(setShowRollCalledStations(false));
    dispatch(setShowConsumedHours(false));
    dispatch(setShowContractorStations(false));
    dispatch(setShowPlannedHours(false));
    dispatch(setShowEndOfSerialNumber(false));
    dispatch(setFilterTaktTime(0));
    dispatch(setIssueNumberFilter(0))
  }

  return (
    <React.Fragment>
      <Tooltip title="Issue Filters">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Indicator badgeContent={activeFilters}>
            <FilterIcon color={activeFilters ? "primary" : "inherit"} />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <IssueFilterHeader sx={{padding: 2}}>
          <Typography variant="subtitle1" color="textPrimary">
            {activeFilters || "No"} Active Filters
          </Typography>
        </IssueFilterHeader>
        <React.Fragment>
          <List disablePadding>
            <Filter
              label="Issue Filter"
              filterInput={
                <Select
                  variant="standard"
                  fullWidth
                  value={issueCategoryFilter}
                  onChange={(e) => {
                    dispatch(setIssueCategoryFilter(e.target.value as string));
                  }}
                >
                  <MenuItem value="">No Filter</MenuItem>
                  {uniqueIssueCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              }
              Icon={IssueIcon}
            />
            <Filter
              label="Issue Search"
              Icon={Search}
              filterInput={
                <TextField
                  variant="standard"
                  fullWidth
                  value={issueNumberFilter || ""}
                  id="issue-search-input"
                  type="number"
                  onChange={(e) => {
                    dispatch(setIssueNumberFilter(Number(e.target.value)));
                  }}
                />
              }
            />
            <Filter
              label="Roll Called"
              Icon={RollCallIcon}
              secondaryAction={
                <Switch
                  id="rollCallToggleButton"
                  edge="end"
                  checked={showRollCalled}
                  onChange={() =>
                    dispatch(setShowRollCalledStations(!showRollCalled))
                  }
                />
              }
            />
            <Filter
              label="Consumed Hours"
              Icon={Timelapse}
              secondaryAction={
                <Switch
                  id="consumedHoursToggleButton"
                  edge="end"
                  checked={showConsumedHours}
                  onChange={() =>
                    dispatch(setShowConsumedHours(!showConsumedHours))
                  }
                />
              }
            />
            <Filter
              label="Contractors"
              Icon={PersonPinCircle}
              secondaryAction={
                <Switch
                  id="displayContractorToggleButton"
                  edge="end"
                  checked={showContractorStations}
                  value={showContractorStations}
                  onChange={() =>
                    dispatch(setShowContractorStations(!showContractorStations))
                  }
                />
              }
            />
            <Filter
              label="Planned Hours"
              Icon={ScheduleIcon}
              secondaryAction={
                <Switch
                  id="displayPlannedHoursButton"
                  edge="end"
                  checked={showPlannedHours}
                  value={showPlannedHours}
                  onChange={() =>
                    dispatch(setShowPlannedHours(!showPlannedHours))
                  }
                />
              }
            />
            <Filter
              label="End Of Serial #"
              Icon={Tag}
              secondaryAction={
                <Switch
                  id="displayPlannedHoursButton"
                  edge="end"
                  checked={showEndOfSerialNumber}
                  value={showEndOfSerialNumber}
                  onChange={() =>
                    dispatch(setShowEndOfSerialNumber(!showEndOfSerialNumber))
                  }
                />
              }
            />
            <Filter
              label="Takt Time"
              Icon={TaktTimeIcon}
              filterInput={
                <TextField
                  variant="standard"
                  fullWidth
                  value={taktTime || ""}
                  id="takt-time-filter-input"
                  type="number"
                  onFocus={() =>
                    (document.getElementById(
                      "takt-time-filter-input"
                    ) as HTMLInputElement)?.select()
                  }
                  onChange={(e) => {
                    dispatch(setFilterTaktTime(Number(e.target.value)));
                  }}
                />
              }
            />
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => clearAllFilters()}>
              Clear all Site Filters
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default SiteFilterDropdown;
