import React from "react";
import { RouteType } from "types/routes";
import Header from "components/header/AppBar";
import Sidebar from "components/sidebar/Sidebar";
import {
  CssBaseline,
  Paper,
  GlobalStyles,
  styled,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarOpen, selectSideBarOpen } from "store/slices/systemSlice";

const drawerOpenWidth = 258;
const drawerClosedWidth = 65;

const Root = styled("div")`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled("div")<{ open: boolean }>`
  margin-right: ${({ open }) => (open ? drawerOpenWidth : drawerClosedWidth)}px;
  transition-duration: 0.2s;
  z-index: 1;
`;

const AppContent = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

const MainContent = styled(Paper)<{ drawerwidth: number }>`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
  children: React.ReactNode;
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  routes,
  width,
}) => {
  const theme = useTheme();
  const open = useSelector(selectSideBarOpen);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(setSideBarOpen(!open));
  };

  function getDrawerWidth() {
    return open ? drawerOpenWidth : drawerClosedWidth;
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyles
        styles={{
          html: { height: "100%" },
          body: {
            height: "100%",
            background: theme.palette.background.default,
          },
          root: { height: "100%" },
        }}
      />
      <Drawer open={open}>
        <Sidebar
          routes={routes}
          PaperProps={{ style: { width: getDrawerWidth() } }}
          open={open}
          onClose={handleDrawerToggle}
        />
      </Drawer>
      <AppContent>
        <Header open={open} onDrawerToggle={handleDrawerToggle} />
        <MainContent elevation={0} drawerwidth={getDrawerWidth()}>
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
