import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserInstancesQuery, useLazyGetUserInstancePermissionQuery } from "store/services/instances";
import { selectAtlasInstance, selectIsAuthorized, selectLoggedInUser, setAtlasInstance, setInstanceRole } from "store/slices/authSlice";
import { setGlobalAlert } from "store/slices/systemSlice";

const useAtlasInstances = () => {
  const dispatch = useDispatch();
  const atlasInstance = useSelector(selectAtlasInstance)
  const loggedInUser = useSelector(selectLoggedInUser);
  const isAuthorized = useSelector(selectIsAuthorized);
  const [getInstancePermission] = useLazyGetUserInstancePermissionQuery();
  const {
    data: userInstances = [],
    isLoading: isLoadingUserInstances,
    error: userInstancesError,   
  } = useGetUserInstancesQuery(undefined, {skip: !isAuthorized});

  useEffect(() => {
    if (userInstances.length === 1) {
      dispatch(setAtlasInstance(userInstances[0].name));
    }
  }, [userInstances]);

  useEffect(() => {
    if (atlasInstance) {
      getInstancePermission({user: loggedInUser, instanceName: atlasInstance}).unwrap().then((permission) => {
        dispatch(setInstanceRole(permission.userRole))
      }).catch((error) => {
        dispatch(setGlobalAlert({show: true, message: `${error}`, severity: "error"}))
      })
    }
  }, [atlasInstance])

  return {
    userInstances,
    isLoadingUserInstances,
    userInstancesError
  };
};

export default useAtlasInstances;
